<template>
  <div>
     <!-- <v-btn dark class="blue" @click="DownloadPO()">
        Download Invoice
     </v-btn> -->
     <!-- <v-btn :disabled="!EnableSelect || SelectedTransactionLineItems.length === 0" @click="PrepareInvoiceSOObject()" >Create Invoice</v-btn> -->
     <!-- <v-btn :disabled="!EnableSelect" @click="PrepareInvoiceSOObject()" >Create Invoice</v-btn> -->
      <v-dialog style="z-index:10 !important;" max-width="800" v-model="NEWSOInvoiceDialog">
      	
      <v-card id="NewPOElement" v-if="ComputedSOInvoiceObject">
        <v-card-title class="elevation-3 recordtoolbar white--text justify-center">New Invoice Review
        </v-card-title>
        <v-card-text>
        <v-card-text>
          <v-row class="justify-space-between"
            no-gutters
          >
            <v-col cols="6" style="align-self: center;"
            >
             <v-img width="80%"             
                contain
                src="@/assets/logo.png">
              </v-img>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ComputedSOInvoiceObject.Company_Name ? ComputedSOInvoiceObject.Company_Name : ''}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>              
             <v-list-item dense v-html="ComputedSOInvoiceObject.PO_Bill_To_Address_Text">
              </v-list-item>
            </v-col>
            <v-col cols="6" style="margin-top: 30px;">
               <v-card-title class="mediumoverline"> Invoice Number here
                  </v-card-title>
                <v-list class="background" style="margin-top: 30px;">
                 
                   <v-list-item dense v-for="field in ComputedPORightHeaderFields" :key="field.itemObjKey">
                      <v-list-item-content  :style="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address' ? 'height: 350px;padding-left: 5px;' : 'height: 100%;padding-left: 5px;'">
                          {{field.DisplayName}}
                      </v-list-item-content>
                      <!-- <v-divider></v-divider> -->
                      <v-list-item-content  style="padding-left: 5px;justify-self: end;" v-if="field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'">
                       
                      <FieldViewerComponent  :field="field" :Record="ComputedSOInvoiceObject"
                      />
                      </v-list-item-content>
                      <v-list-item-content class="background" v-if="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address'">
                         
                    <v-list class="transparent mx-4" outlined>
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="configtext--text">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="datatext--text">
                                {{ComputedSOInvoiceObject[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                      </v-list-item-content>
                      
                  </v-list-item>
                </v-list>
            </v-col>
          </v-row>
        
        </v-card-text>
        <v-card-text>
           <v-row v-if="ComputedSOInvoiceObject.Client"
            no-gutters
          >
            <v-col>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ComputedSOInvoiceObject.Customer_Contact}}
                  </v-list-item-subtitle>
                  <v-list-item-title style="font-size: 18px;">
                    {{ComputedSOInvoiceObject.Customer_Company}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
             <v-list-item dense v-html="ComputedSOInvoiceObject.SO_Delivery_Address_Text">
              </v-list-item>
            </v-col>
            <v-col >
            
            </v-col>
           </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text v-if="CashLoanEntity && InstallmentLine">
          <v-card-text class="text-center">
            <!--  grey lighten-4 -->
            <v-list class="transparent">
                <!--  v-for="inst in CurrentInvoiceInstallments" :key="inst.itemObjKey" -->
            <v-list-item class="detailslistoutline">
                <v-list-item-content class="caption" style="text-align: start;">
                    Installment {{InstallmentLineNumber}} of {{SelectedPackageInstallments.length}}<br>Due {{TimestampFormatterSTRINGDayText(InstallmentLine.Due_Date)}}
                </v-list-item-content>
                <!-- <v-list-item-avatar>
                    <v-icon>mdi-calendar</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="caption" style="text-align: start;">
                    {{TimestampFormatterSTRINGDayText(InstallmentLine.Due_Date)}}
                </v-list-item-content> -->
                <v-list-item-action style="text-align: end;">
                    {{CurrencyFormatter(InstallmentLine.Amount,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-action>
            </v-list-item>
            </v-list>
        </v-card-text>
        </v-card-text>
        <v-card-text style="padding:30px;border: 1px solid grey;" v-if="StoreOrderEntity">
          <v-form ref="polineitemsform">
          <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="POLineHeadersforTable"
      :items="ComputedNewPOLineItems"
      
     
     class="transparent"
      >
      <template v-slot:[`item.Qty`]="{ item }">
        <v-text-field readonly style="font-size:13px;" :rules="PRQuantityRules(item)" v-model.number="item.Qty"  type="number"/>
      </template>
      <template v-slot:[`item.Price`]="{ item }">
        <v-text-field :readonly="!item.Last_Quoted_Price" style="font-size:13px;" :value="CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)" v-model.number="item.Price" :label="item.Last_Quoted_Price ? CurrencyFormatter(item.Last_Quoted_Price,$store.state.DefaultCurrency.Currency)+' (Q)' : ''"  type="number"/>
      </template>
      <template v-slot:[`item.Created_On`]="{ item }">
        {{item.Created_On.toDateString()}}
      </template>
      <template v-slot:[`item.Sub_Total`]="{ item }">
         {{CurrencyFormatter(item.Sub_Total,$store.state.DefaultCurrency.Currency)}}
      </template>
          </v-data-table>
          </v-form>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-text>
           <v-row
            no-gutters
            style="padding-top: 10px;"
          >
            <v-col cols="6">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Notes
                  </v-list-item-subtitle>
                  <span class="caption" v-html="ComputedSOInvoiceObject.Footer_Note">
                  </span>
                </v-list-item-content>
              </v-list-item>
           
            </v-col>
            <v-col cols="6">
              <v-list-item  dense>
                <v-list-item-content>
                <v-list-item-subtitle>
                 SubTotal
                </v-list-item-subtitle>
                </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(ComputedSOInvoiceObject.Sub_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="!CashLoanEntity">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Discount ({{SelectedSupplier.Default_Discount}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(DiscountValue,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="ComputedSOInvoiceObject.Delivery && !CashLoanEntity">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Freight
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(ShippingTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="SelectedBusinessUnit.Tax_Percentage && !CashLoanEntity">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Tax ({{SelectedBusinessUnit.Tax_Percentage}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(TaxTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="border: 2px solid black;">
                 <v-list-item-content>
                <v-list-item-title>
                  Total:
                </v-list-item-title>
                 </v-list-item-content>
                <v-list-item-content>
                  {{CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
            </v-col>
           </v-row>
        </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelProcessNewSOInvoice()" dark color="warning">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
           <v-btn dark class="blue" @click="DownloadPO(true)">
            <v-icon>mdi-download</v-icon>Download
          </v-btn>
          <v-btn dark class="success" @click="ProcessPO()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[POPDFFunctionData]"		:PassedFunction="POPDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';
export default {
    props: ['System','SystemEntities','CurrentEntity','Record','LookupObj','PrepInvoice'],
    components: {PDFExporter,FieldViewerComponent},
    data() {
        return {
          HasClientRecord: false,
            SOLineItems: [],
            POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Warehouse',Type: 'Lookup',LookupFieldName: 'Name',propvalue: 'Warehouse',value: 'Warehouse.Name',class: 'overline',TableHidden: true},
              {text: 'Item',propvalue: 'StockObj',value: 'StockObj.Item_Name',Type: 'Lookup',LookupFieldName: 'Item_Name',class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Created Date',propvalue: 'Created_On',value: 'Created_On',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',propvalue: 'Sales_Order',value: 'Sales_Order.Name',class: 'overline',TableHidden: true},
            ],
          NewInvoice_Number: '',
          PDFDownloadOnly: false,
          ExportingtoPDFDialog: false,
          rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
          },
          Bill_To_Address: {Name: 'Bill_To_Address',FieldBreakdown: []},
          Delivery_Address: {Name: 'Delivery_Address',FieldBreakdown: []},
          Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
          Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
          PORightHeaderFields: [
            {Name: 'Created_On',DisplayName: 'Invoice Date',Type: 'Date'},
            {id: 'Customer_ID',Name: 'Customer_ID',DisplayName: 'Customer ID',Type: 'Single Line Text'},
          ],
          NewPOObject: {Supplier: {},Footer_Note: `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`},
          DefaultNewPOObject: {Supplier: {},Footer_Note: `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`},
          NewPOLineItems: [],
          NEWSOInvoiceDialog: false,
           TransactionalLineItems: [],
        SelectedTransactionLineItems: [],
        SelectedSupplier: '',
        OrderforDelivery: true,
        PDFExportFunctionData: '',
        DefaultTransactionPath: '',
        LinesRef: '',
        SelectedSalesOrder: '',
        OfficialSelectedSalesOrder: '',
        SelectedBillingAccount: '',
        SelectedUserCompany: '',
        }
    },	
    computed:{      
      DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      InstallmentLineNumber(){
        let nr = 0
        if(this.InstallmentLine){
          nr = this.InstallmentLine.LineNr
        }
        return nr
      },
      InstallmentLine(){
        return this.SelectedPackage ? this.SelectedPackageInstallments.find(obj => !obj.Invoice_Number) : ''
      },
      InstallmentLineinArray(){
        return this.InstallmentLine ? [this.InstallmentLine]: []
      },
      SelectedPackage(){
        if(this.CashLoanEntity && this.OfficialSelectedSalesOrder){
          let pack = this.OfficialSelectedSalesOrder.Package
          pack.Line_Items = pack.Line_Items.map(lineitem => {
            if(lineitem.Due_Date.toDate){
            lineitem.Due_Date = lineitem.Due_Date.toDate()
            }
            return lineitem
          })
          return pack
        }
      },
      ComputedTotal(){
            return this.SelectedPackage && this.OfficialSelectedSalesOrder ?  this.OfficialSelectedSalesOrder.Loan_Amount+(this.OfficialSelectedSalesOrder.Loan_Amount*this.SelectedPackage.Interest) : 0
        },
      CurrentInvoiceInstallments(){
        console.log(this.InstallmentLine)
        return this.InstallmentLine ? this.SelectedTransactionLineItems.filter(item => {
          console.log(item.Due_Date,this.InstallmentLine.Due_Date)
              return item.Due_Date === this.InstallmentLine.Due_Date
            }) : []
      },
      SelectedPackageInstallments(){
            let array = []
            if(this.SelectedPackage){
              array = this.SelectedPackage.Line_Items
                // for (var x = 0; x<this.SelectedPackage.Period;x++){
                //     var today = new Date();
                //     let linedue =  this.EndofMonth(new Date(today.getFullYear(), today.getMonth()+1+x, 0)) 
                //     let amount = this.ComputedTotal/this.SelectedPackage.Period               
                //     array.push(
                //         {Amount: amount, Due_Date: linedue}
                //     )
                // }
            }
            return array
        },
      EnableSelect(){
        //console.log(this.OfficialSelectedSalesOrder,this.SelectedSalesOrder)
        return this.OfficialSelectedSalesOrder && this.OfficialSelectedSalesOrder.id
      },
        LookupObjSelectedSalesOrder(){
          let obj = Object.assign({},this.SelectedSalesOrder)
          delete obj.Sales_OrderObj
          delete obj.PrimaryName
          delete obj.SubColRecordid
          delete obj.PrimaryName
          return obj
        },
        StoreOrderEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Order'
        },
        CashLoanEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Cash Loan'
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        POPDFFunctionData(){
        let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //ComputedNewPOLineItemsTable applicable if store order entity, if cashloanentity then cashloaninstallment only
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'ComputedNewPOLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.StoreOrderEntity ? this.ComputedNewPOLineItemsTable : this.CashLoanEntity ? this.ComputedCashLoanItemsTable : ''
        newelemnt.Justify = 'center'
        let lineitemslength = 0
        if(this.StoreOrderEntity){
          lineitemslength = this.ComputedNewPOLineItems.length
        }
        else if(this.CashLoanEntity){
          lineitemslength = this.InstallmentLineinArray.length
        }
        if(lineitemslength <= 3){
          newelemnt.PaddingTop = 425
        }
        else if(lineitemslength <= 6){
          newelemnt.PaddingTop = 400
        }
        else if(lineitemslength <= 9){
          newelemnt.PaddingTop = 375
        }
        else if(lineitemslength <= 12){
          newelemnt.PaddingTop = 350
        }
        else if(lineitemslength <= 15){
          newelemnt.PaddingTop = 325
        }
        else if(lineitemslength <= 18){
          newelemnt.PaddingTop = 300
        }
        else {
          newelemnt.PaddingTop = 300
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata
      },
      PDFPOData(){
        let sampleobj = JSON.parse(JSON.stringify(this.ComputedSOInvoiceObject))
        console.log('sampleobj',sampleobj.Created_On)
        sampleobj.SubCollections = []
        // this.PDFPages.map(page => {
          // if(page.Elements){
          //   page.Elements.map(elmnt => {
          //     //console.log('elmnt',elmnt)  
          //     if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type !== 'Option Set' && elmnt.DataField.Type !== 'Lookup' && elmnt.DataField.Type !== 'Radio Group'){
          //       sampleobj[elmnt.DataField.Name] = elmnt.DataField.DisplayName
          //     }
          //     else if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type === 'Option Set'){
          //       sampleobj[elmnt.DataField.Name] = {ID: 1000001, Name: elmnt.DataField.DisplayName}
          //     }
          //     //console.log('sampleobj',sampleobj)
          //   })
          // }
        // })
        return sampleobj
      },
      POLineHeadersforTable(){
        return this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
      },
      ComputedCashLoanItemsTable(){
        //let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;padding:5px;border: 1px solid grey;">
                    <tr>
                      <th class="overline" style="text-align: left;">Installment</th>
                      <th class="overline" style="text-align: left;">Amount</th>
                    </tr>
                    <tr class="detailslistoutline">
                      <td style="text-align: left;"> Installment #`+this.InstallmentLineNumber+` of `+this.SelectedPackageInstallments.length+`<br>
                      Due - `+this.TimestampFormatterSTRINGDayText(this.InstallmentLine.Due_Date)+`</td>
                      <td style="text-align: left;">`+this.CurrencyFormatter(this.InstallmentLine.Amount,this.$store.state.DefaultCurrency.Currency)+`</td>
                    </tr>`
        // this.InstallmentLineinArray.map(lineitem => {
        //   html = html+`
        //   <tr class="detailslistoutline">`
        //   this.POLineHeadersforTable.map((head,headnindex) => {
        //     //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
        //     let value = lineitem[head.propvalue]
        //     if(head.Type === 'Number Field' && head.IsCurrency){
        //       value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
        //     }
        //     else if(head.Type === 'Date' && !head.HasTimeInput){
        //       value = value.toDateString()
        //     }
        //     else if(head.Type === 'Lookup'){
        //       value = value[head.LookupFieldName]
        //     }
        //     html = html+`
        //     <td style="text-align: left;">`+value+`</td>`
        //     if(headnindex-1+2 === headerslength){
        //       html = html+`
        //       </tr>`
        //     }
        //   })
        // })
        html = html+`
        </table>`
        return html
      },
      ComputedNewPOLineItemsTable(){
        let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;padding:5px;border: 1px solid grey;">
                    <tr>`
        this.POLineHeadersforTable.map(head => {
          html = html+`
          <th class="overline" style="text-align: left;">`+head.text+`</th>`
        })
        html = html+`
        </tr>`
        this.ComputedNewPOLineItems.map(lineitem => {
          html = html+`
          <tr class="detailslistoutline">`
          this.POLineHeadersforTable.map((head,headnindex) => {
            //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
            let value = lineitem[head.propvalue]
            if(head.Type === 'Number Field' && head.IsCurrency){
              value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
            }
            else if(head.Type === 'Date' && !head.HasTimeInput){
              value = value.toDateString()
            }
            else if(head.Type === 'Lookup'){
              value = value[head.LookupFieldName]
            }
            html = html+`
            <td style="text-align: left;">`+value+`</td>`
            if(headnindex-1+2 === headerslength){
              html = html+`
              </tr>`
            }
          })
        })
        html = html+`
        </table>`
        return html
      },
      ComputedNewPOLineItems(){
        let arr = this.NewPOLineItems.map((item,index) => {
        //   if(typeof item.Delivery_DateMenu === 'undefined'){
        //     item.Delivery_DateMenu = false
        //   }
          if(item.Created_On.toDate){
              item.Created_On = item.Created_On.toDate()
          }
        //   if(typeof item.Delivery_Date_Input === 'undefined'){
        //       let date = item.Created_On
        //       if(date.toDate){
        //           date = date.toDate()
        //       }
        //     item.Delivery_Date_Input = date.toISOString().substring(0,10)
                     
        //   }
          
          item.Nr = index-1+2
          return item
        }).map(item => {
          //item.Created_On = new Date(item.Delivery_Date_Input)    
          item.Sub_Total = Number(item.Qty)*Number(item.Price)
          return item
        })
        return arr
      },
      CollectionorDeliveryDisabled(){
        let disabled = false
        if(this.SelectedSupplier.Collection){
          disabled = true
        }
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(typeof this.ComputedSOInvoiceObject[newname] === 'undefined'){
            disabled = true
          }
        })
        return disabled
      },
      POSubTotal(){
        let total = 0
        if(this.StoreOrderEntity){
          total = this.NewPOLineItems.map(polineitem => {
          return polineitem.Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        }
        else if(this.CashLoanEntity){
          return this.InstallmentLine.Amount
        }
        return Number(total.toFixed(2))
      },
      InvoiceFinalPaymentDate(){
         let d = new Date()
         d.setDate(d.getDate() + 7);
        return d
      },
      ComputedSOLineItems(){
        return this.SelectedTransactionLineItems.map(translineitem => {
          let solineobj = translineitem.LookupObj
          solineobj.Sales_Orderid = solineobj.id
          delete solineobj.id
          solineobj.Purchase_Requisitionid = translineitem.Transaction_Docid
          solineobj.PRLine_Itemid = translineitem.id
          solineobj.Qty = translineitem.Qty
          solineobj.Outstanding_Qty = translineitem.Outstanding_Qty
          solineobj.Open_Allocations = translineitem.Open_Allocations
          solineobj.Created_On = translineitem.Created_On
          solineobj.Modified_On = translineitem.Modified_On
          solineobj.Warehouse = translineitem.Warehouse
          solineobj.Warehouseid = translineitem.Warehouseid
          solineobj.StockObj = translineitem.StockObj

          return solineobj
        })
      },
      ComputedSOInvoiceObject(){
        let POObject = {}
        if(this.StoreOrderEntity){
          POObject.Invoice_Type = {ID: 1000001, Name: 'Store Order'}
        }
        else if(this.CashLoanEntity){
          POObject.Invoice_Type = {ID: 1000004, Name: 'Cash Loan'}
          POObject.Progress = {ID: 1000002,Name: 'Invoiced'}
          POObject.Due_Date = this.InstallmentLine.Due_Date
          POObject.PaymentSchedule = this.SelectedPackage.PaymentSchedule
          //okay if there is a follow up item, then add New_Due_Date
          if(this.InstallmentLineNumber < this.SelectedPackageInstallments.length){
            POObject.New_Due_Date = this.SelectedPackageInstallments[this.InstallmentLineNumber].Due_Date
            POObject.Is_Final_Invoice = false
          }  
          else{
            POObject.Is_Final_Invoice = true
          }
          if(this.InstallmentLineNumber === 1){
            POObject.Initial_Invoice = true
          }
          POObject.Loan_Repayment_Amount = this.OfficialSelectedSalesOrder.Grand_Total
          POObject.Loan_Amount = this.OfficialSelectedSalesOrder.Loan_Amount
          POObject.Interest_Amount = this.InstallmentLine.Interest_Amount
          POObject.Package = this.SelectedPackage 
          POObject.LineNr = this.InstallmentLineNumber                       
        }
        if(this.CurrentEntity && this.CurrentEntity.InvoicetoUserCompany){
          POObject.InvoicetoUserCompany = true
        }
        if(this.NEWSOInvoiceDialog && this.SelectedBillingAccount || 
        this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Cash Loan' && this.SelectedBillingAccount){
          if(this.OrderforDelivery){
          POObject.Delivery = true
        }
        else{
          POObject.Delivery = false
        }
        POObject.Created_On = new Date()
        POObject.Client = ''
        POObject.Customer_Company = ''
        POObject.Customer_Contact = ''
        POObject.Customer_ID = ''
        if(this.OfficialSelectedSalesOrder && this.OfficialSelectedSalesOrder.User){
          POObject.Customer_Contact = this.OfficialSelectedSalesOrder.User.Full_Name
          POObject.Client = this.OfficialSelectedSalesOrder.User
          POObject.Clientid = this.OfficialSelectedSalesOrder.User.id
        }
        if(this.SelectedBillingAccount){
          POObject.Customer_Company = this.SelectedBillingAccount.Account_Name
          POObject.Customer_ID = this.SelectedBillingAccount.Customer_ID
        }
        let capass = true
        POObject.Invoice_Number = 'Invoice (TBD) #0000001'
        if(this.NewInvoice_Number){
          POObject.Invoice_Number = this.NewInvoice_Number
        }
        if(this.StoreOrderEntity){
        POObject.Progress = {ID: 1000001,Name: 'Draft'}
        if(this.PaidinFull){
          //let's hope for now it would always be due to an ActiveQuoteid prop, not another reason for being "Paid"
          POObject.Advance_Payment_id = this.OfficialSelectedSalesOrder.Active_Quoteid
          POObject.Progress = {ID: 1000004,Name: 'Paid'}
          POObject.Amount_Paid = Number(this.GrandTotal)
        }
        }
        POObject.RecordPrimaryFieldName = 'Invoice_Number'
        if(this.StoreOrderEntity){
            POObject.Company_Name = this.SelectedWarehouse.Company_Name
        }
        else if(this.CashLoanEntity){
          POObject.Company_Name = this.SelectedBillingAccount.Client_Reference
          if(this.CurrentEntity.InvoicetoUserCompany && this.SelectedUserCompany){
            POObject.Company_Name = this.SelectedUserCompany.Company_Name
          }
        }
        
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(this.SelectedSupplier[brd.Name]){
            POObject[newname] = this.SelectedSupplier[brd.Name]
          }
          else{
            capass = false
          }
        })
        let dapass = true
        this.Delivery_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Delivery_Address_').join('SO_Delivery_Address_')
          if(this.OfficialSelectedSalesOrder[brd.Name]){
            POObject[newname] = this.OfficialSelectedSalesOrder[brd.Name]
          }
          else{
            dapass = false
          }
        })
        let btapass = true
        this.Bill_To_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Bill_To_Address_').join('PO_Bill_To_Address_')
          let brdname = brd.Name.split('Bill_To_Address_').join('Address_')
          if(this.CashLoanEntity){
            brdname = brd.Name.split('Bill_To_Address_').join('Invoicing_Address_')
          }
          //console.log(this.SelectedUserCompany[brdname])
          if(this.SelectedBusinessUnit[brdname]){
            POObject[newname] = this.SelectedBusinessUnit[brdname]
          }
          else if(this.CashLoanEntity && this.SelectedUserCompany && this.SelectedUserCompany[brdname]){
            POObject[newname] = this.SelectedUserCompany[brdname]
          }
          else{
            btapass = false
          }
        })
        POObject.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Created_Byid = this.userLoggedIn.id
        POObject.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Modified_Byid = this.userLoggedIn.id
        POObject.Created_On = new Date()
        POObject.Modified_On = new Date()
        POObject.BillingAccount = {Client_Reference: this.SelectedBillingAccount.Client_Reference,Customer_ID: this.SelectedBillingAccount.Customer_ID,id: this.SelectedBillingAccount.id}
        POObject.BillingAccountid = this.SelectedBillingAccount.id
        if(dapass){
        POObject.SO_Delivery_Address_Text = ``+POObject.SO_Delivery_Address_Street_Number+` `+POObject.SO_Delivery_Address_Street_Name+`, `+POObject.SO_Delivery_Address_Suburb+`, `+POObject.SO_Delivery_Address_Postal_Code+`<br>
        `+POObject.SO_Delivery_Address_City+`, `+POObject.SO_Delivery_Address_State_Province+`,<br>
        `+POObject.SO_Delivery_Address_Country_Region  
        }
        if(btapass && this.SelectedWarehouse){        
        POObject.PO_Bill_To_Address_Text = ``+this.SelectedWarehouse.Bill_To_Address_Street_Number+` `+this.SelectedWarehouse.Bill_To_Address_Street_Name+`, `+this.SelectedWarehouse.Bill_To_Address_Suburb+`, `+this.SelectedWarehouse.Bill_To_Address_Postal_Code+`<br>
        `+this.SelectedWarehouse.Bill_To_Address_City+`, `+this.SelectedWarehouse.Bill_To_Address_State_Province+`,<br>
        `+this.SelectedWarehouse.Bill_To_Address_Country_Region
        }
        else if(this.CashLoanEntity && this.SelectedUserCompany){
          POObject.PO_Bill_To_Address_Text = ``+this.SelectedUserCompany.Invoicing_Address_Street_Number+` `+this.SelectedUserCompany.Invoicing_Address_Street_Name+`, `+this.SelectedUserCompany.Invoicing_Address_Suburb+`, `+this.SelectedUserCompany.Invoicing_Address_Postal_Code+`<br>
        `+this.SelectedUserCompany.Invoicing_Address_City+`, `+this.SelectedUserCompany.Invoicing_Address_State_Province+`,<br>
        `+this.SelectedUserCompany.Invoicing_Address_Country_Region
        }
        if(this.StoreOrderEntity){
        POObject.Warehouse = {Name: this.SelectedWarehouse.Name,id: this.SelectedWarehouse.id}
        POObject.Warehouseid = this.SelectedWarehouse.id
        POObject.Business_Unit = {Name: this.SelectedWarehouse.Business_Unit.Name, id: this.SelectedWarehouse.Business_Unitid}
        POObject.Business_Unitid = this.SelectedWarehouse.Business_Unitid
        }
        else{
            POObject.Business_Unit = {Name: this.Record.Business_Unit.Name, id: this.Record.Business_Unitid}
        POObject.Business_Unitid = this.Record.Business_Unitid
        }
        if(this.StoreOrderEntity){
        POObject.Footer_Note = `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`
        }
        else if(this.CashLoanEntity){
          POObject.Footer_Note = `<p>The amount of the Invoice is the installment due for the indicated month. The full repayment of `+this.OfficialSelectedSalesOrder.id+` on `+this.SelectedBillingAccount.id+` amount can be found on your statement.</p>`
        }
        POObject.Sub_Total = this.POSubTotal  
        POObject.Invoice_Total = this.VatExclusiveInvoiceTotal
        //POObject.Supplier_Account_Type = this.SelectedSupplier.Billing_Type
        //alert(this.SelectedSupplier.Billing_Type.Name)
        POObject.Payment_Terms = ''
        if(this.SelectedSupplier && this.SelectedSupplier.Billing_Type && this.SelectedSupplier.Billing_Type.Name === 'COD'){
          POObject.Payment_Terms = 'COD'
          POObject.Payment_Period = 0
        }
        else if(this.SelectedSupplier && this.SelectedSupplier.Billing_Type && this.SelectedSupplier.Billing_Type.Name === 'Credit'){          
          POObject.Payment_Period = this.SelectedSupplier.Days_for_Payment
          POObject.Payment_Terms = 'Net '+this.SelectedSupplier.Days_for_Payment
        }
        //POObject.Supplier_Account_Type = this.SelectedSupplier.Billing_Type
         if(this.StoreOrderEntity){
        POObject.Shipping_Method = {ID: 1000001,Name: 'FOB'}
        POObject.Due_Date = this.InvoiceFinalPaymentDate
         }
        
        POObject.Tax_Total = this.TaxTotal
        POObject.Tax_Percentage = this.TaxPercentage
        POObject.Discount_Total = this.DiscountValue
        POObject.Freight_Total = this.ShippingTotal
        if(typeof POObject.Delivery === ' undefined'){
          POObject.Delivery = !this.SelectedSupplier.Collection
        }
        POObject.Grand_Total = this.GrandTotal
        POObject.Status = this.DefaultStatusField.DefaultOption
        POObject.Status_Reason = this.DefaultStatusField.DefaultLevel2Option
        return POObject
        }
        else{
          return ''
        }
        
      },
      ComputedPORightHeaderFields(){
        return this.OrderforDelivery ? this.PORightHeaderFields : this.PORightHeaderFields.filter(field => {
          return field.Name !== 'Shipping_Method'
        })
      },
      GrandTotal(){
        let total = 0
        if(this.StoreOrderEntity) {
        this.POSubTotal
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        if(this.TaxTotal !== 0){
          total = total+this.TaxTotal
        }
        }
        else if(this.CashLoanEntity){
            total = this.InstallmentLine.Amount
        }
        return Number(total.toFixed(2))
      },
      VatExclusiveInvoiceTotal(){
        let total = this.POSubTotal
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        return Number(total.toFixed(2))
      },
      ShippingTotal(){
        if(this.SelectedSupplier.Collection){
          return 0
        }
        else{
          return 0
        }
      },
      COGSAvgTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.StockObj.Average_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      COGSLatestQuotedPriceTotal(){
        let total = this.StoreOrderEntity ? this.NewPOLineItems.map(polineitem => {
          return polineitem.StockObj.Latest_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0) : this.CashLoanEntity && this.InstallmentLineNumber === 1 ? this.Record.Loan_Amount : 0
        return Number(total.toFixed(2))
        //Remember COGS only applicable with the very first invoice in the cash loans scheduled invoices
      },
      COGSLatestPurchasePriceTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.StockObj.Latest_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      SelectedBusinessUnit(){
        return this.SelectedWarehouse ? this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.SelectedWarehouse.Business_Unitid) : 
        this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.Record.Business_Unitid)
      },
      TaxPercentage(){
        return this.SelectedBusinessUnit && typeof this.SelectedBusinessUnit.Tax_Percentage !== 'undefined' ? this.SelectedBusinessUnit.Tax_Percentage : 0
      },
      TaxTotal(){
        if(this.SelectedBusinessUnit && this.SelectedBusinessUnit.id && this.StoreOrderEntity){
          if(this.SelectedBusinessUnit.Tax_Percentage !== 0){            
            let total = this.POSubTotal*(this.SelectedBusinessUnit.Tax_Percentage/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      DiscountValue(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          if(typeof this.SelectedSupplier.Default_Discount !== 'undefined' && this.SelectedSupplier.Default_Discount !== 0){
            let total = this.POSubTotal*(this.SelectedSupplier.Default_Discount/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      TextSupplierInvoiceAddress(){        
        return this.SelectedSupplier && this.SelectedSupplier.id ? ``+this.SelectedSupplier.Invoice_Address_Street_Number+` `+this.SelectedSupplier.Invoice_Address_Street_Name+`, `+this.SelectedSupplier.Invoice_Address_Suburb+`, `+this.SelectedSupplier.Invoice_Address_Postal_Code+`<br>
        `+this.SelectedSupplier.Invoice_Address_City+`, `+this.SelectedSupplier.Invoice_Address_State_Province+`,<br>
        `+this.SelectedSupplier.Invoice_Address_Country_Region : ''
      },
      TextBillToAddress(){        
        return this.ComputedSOInvoiceObject? ``+this.ComputedSOInvoiceObject.Bill_To_Address_Street_Number+` `+this.ComputedSOInvoiceObject.Bill_To_Address_Street_Name+`, `+this.ComputedSOInvoiceObject.Bill_To_Address_Suburb+`, `+this.ComputedSOInvoiceObject.Bill_To_Address_Postal_Code+`<br>
        `+this.ComputedSOInvoiceObject.Bill_To_Address_City+`, `+this.ComputedSOInvoiceObject.Bill_To_Address_State_Province+`,<br>
        `+this.ComputedSOInvoiceObject.Bill_To_Address_Country_Region : ''
      },
      TextDeliveryAddress(){        
        return ``+this.ComputedSOInvoiceObject.PO_Delivery_Address_Street_Number+` `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Street_Name+`, `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Suburb+`, `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Postal_Code+`<br>
        `+this.ComputedSOInvoiceObject.PO_Delivery_Address_City+`, `+this.ComputedSOInvoiceObject.PO_Delivery_Address_State_Province+`,<br>
        `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Country_Region
      },
    },
    watch: {
      PrepInvoice(v){
        if(v){
          this.PrepareInvoiceSOObject()
        }  
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.PrepareRecord()
        //this.PackageFix()
        this.SelectedBillingAccount = this.$store.state.BillingAccountsArray.find(obj => obj.id === this.Record.BillingAccountid)
        this.SelectedSalesOrder = this.LookupObj
        this.PrepareAddressField('Bill_To_Address')
        this.PrepareAddressField('Delivery_Address')
        this.PrepareAddressField('Invoice_Address')
        this.PrepareAddressField('Collection_Address')   
    },
    methods:{
      PrepareRecord(){
        this.GetPOPDFFunction()
        this.CheckClientRecord()
        this.OfficialSelectedSalesOrder = Object.assign({},this.Record)
        this.SelectedSalesOrder = this.LookupObj

        this.PrepBA()
        
        
      },
      PrepBA(){
        this.SelectedBillingAccount = this.$store.state.BillingAccountsArray.find(obj => obj.id === this.Record.BillingAccountid)
        if(!this.SelectedBillingAccount){
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.Record.BillingAccountid).onSnapshot(snapshot => {
            let badoc = snapshot.data()
            badoc.id = this.Record.BillingAccountid
            this.SelectedBillingAccount = badoc
            if(this.CashLoanEntity && this.SelectedBillingAccount && this.CurrentEntity.InvoicetoUserCompany){
              this.PrepUserComp()
            }
          })
        }
        else{
          if(this.CashLoanEntity && this.SelectedBillingAccount && this.CurrentEntity.InvoicetoUserCompany){
            this.PrepUserComp()
          }
        }
      },
      PrepUserComp(){
        this.SelectedUserCompany = this.$store.state.UserCompaniesArray.find(obj => obj.id === this.SelectedBillingAccount.Companyid)
        if(!this.SelectedUserCompany){
           db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('usercompanies').doc(this.SelectedBillingAccount.Companyid).onSnapshot(snapshot => {
            let compdoc = snapshot.data()
            console.log(compdoc)
            compdoc.id = this.SelectedBillingAccount.Companyid
            this.SelectedUserCompany = compdoc
            console.log(this.SelectedBillingAccount,this.SelectedUserCompany)
          })
        }
        
      },
      PackageFix(){
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id).collection('LoanPackages').doc('a59yU0jifte90775lMNY').get().then(snapshot => {
          let data = snapshot.data()
          data.Line_Items = this.SelectedPackageInstallments
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id).update({
            Package: data
          })
        })
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        EndofMonth(date){
            console.log(date)
            var month = date.getMonth()
            var year = date.getFullYear()
            var d = new Date(year, month + 1, 0)
            d.setHours(23,59,59,999)
            return d
            },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
        DeactivateExportingtoPDFDialog(doc){        
        if(!this.PDFDownloadOnly){
         this.ProcessNewInvoice(doc) 
        }        
        this.ExportingtoPDFDialog = false
        this.PDFDownloadOnly = false
      },
      CancelProcessNewSOInvoice(){
        this.NEWSOInvoiceDialog = false
        this.NewPOLineItems = [] 
      },
      PrepareInvoiceSOObject(){
        this.CancelProcessNewSOInvoice()
        if(this.StoreOrderEntity){
        this.PrepareLineItems()
        }
        else if(this.CashLoanEntity){
          this.NEWSOInvoiceDialog = true
        }
        
      },
      PrepareLineItems(){
        let vm = this
        let listlength = this.SelectedTransactionLineItems.length
        this.SelectedTransactionLineItems.map((translineitem,lineitemindex) => {
          let newobj = {}
          newobj.StockObj = Object.assign({},translineitem.StockObj)
          vm.GetStockAverage(newobj).then(resultobj => {
          resultobj.Warehouse = Object.assign({},translineitem.Warehouse)
          resultobj.Warehouseid = translineitem.Warehouse.id
          resultobj.Qty = translineitem.Qty
          resultobj.Min_Qty = translineitem.Qty
        //   resultobj.Supplier = {Supplier_Name: vm.SelectedSupplier.Supplier_Name,id: vm.SelectedSupplier.id}
        //   resultobj.Supplierid = vm.SelectedSupplier.id
          //resultobj.Last_Quoted_Price = translineitem.Last_Quoted_Price
          resultobj.Price = translineitem.Price
          // resultobj.Sub_Total = translineitem.Price*translineitem.Qty
        //   resultobj.BillingAccount = {Supplier_Name: vm.SelectedBillingAccount.Client_Reference,id: vm.SelectedBillingAccount.id}
        //   resultobj.BillingAccountid = vm.SelectedBillingAccount.id
          // resultobj.Purchase_Leadtime = translineitem.Purchase_Leadtime
          // resultobj.Delivery_Date = translineitem.Delivery_Date
          resultobj.TransactionalLineItems = [translineitem.id]
          resultobj.Created_On = translineitem.Created_On
          let oncheck = vm.NewPOLineItems.find(obj => obj.StockObj.id === translineitem.StockObj.id)
          if(!oncheck){
            vm.NewPOLineItems.push(resultobj)
          }
          else{
            oncheck.Qty = oncheck.Qty+translineitem.Qty
            oncheck.Min_Qty = oncheck.Qty
            oncheck.TransactionalLineItems = oncheck.TransactionalLineItems.concat(resultobj.TransactionalLineItems)
            // oncheck.Sub_Total = Number(oncheck.Sub_Total)+Number(resultobj.Sub_Total)
          }
          console.log(vm.SOLineItems,translineitem,resultobj)
          let solinematch = vm.SOLineItems.find(obj => obj.id === translineitem.LookupObj.SubColRecordid)
          if(solinematch){
              resultobj.Price = solinematch.Unit_Price
              //newobj.SalesOrderLineid = solinematch.id 
              
              //newobj.Order_Qty = solinematch.Qty
              console.log(solinematch)
          }
          //newobj.SalesOrderid = translineitem.LookupObj.id
              resultobj.BillingAccount = {Client_Reference: vm.SelectedBillingAccount.Client_Reference,Customer_ID: vm.SelectedBillingAccount.Customer_ID,id: vm.SelectedBillingAccount.id}
              resultobj.BillingAccountid = vm.SelectedBillingAccount.id
              resultobj.Preceding_Transaction_Docid = translineitem.Transaction_Docid
              resultobj.Preceding_TransactionLineid = translineitem.id
              resultobj.Preceding_TransactionDocCollection = vm.DefaultTransactionPath
              resultobj.Preceding_TransactionDocLineCollection = vm.LinesRef
              console.log(translineitem.LookupObj)
              resultobj.LookupObj = Object.assign({},translineitem.LookupObj)
              delete resultobj.LookupObj.Open_Allocations
              delete resultobj.LookupObj.Outstanding_Qty
              if(lineitemindex-1+2 === listlength){
                vm.NEWSOInvoiceDialog = true
              } 
          })
          
        })
        
        // setTimeout(() => {
          
        // }, 300);
        
        console.log(this.SelectedTransactionLineItems,this.SelectedWarehouse,this.SelectedSupplier,this.NewPOLineItems,this.NewPOObject)
      },
      DownloadPO(downloadonly){
        this.ExportingtoPDFDialog = true
        this.PDFDownloadOnly = downloadonly
      },
      ProcessPO(){
        if(this.StoreOrderEntity && this.$refs.polineitemsform.validate() || this.CashLoanEntity){
          let vm = this
          vm.$store.commit('setCustomProcessingDialog',true)
          vm.$store.commit('setCustomProcessingDialogText','Registering Invoice')
          this.PDFDownloadOnly = false
          const functions = firebase.functions();
          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let payload = {
            Docid: 'DispatchInstructions_Number',
            Data: {//must receive value,
            siteid: this.$store.state.ActiveSuiteid
            }
          }
          if(this.StoreOrderEntity){
            payload.Data.Invoice_Type = {ID: 1000001, Name: 'Store Order'}
          }
          if(this.CashLoanEntity){
            payload.Data.Invoice_Type = {ID: 1000004, Name: 'Cash Loan'}
          }
          console.log('AssignAutoNumber payload,payload',payload)
          AssignAutoNumber(payload).then(result => {
          //console.log(result)
          //console.log(result.data)
          vm.NewInvoice_Number = result.data
          vm.$store.commit('setCustomProcessingDialogText','Creating PDF')
          vm.DownloadPO(vm.PDFDownloadOnly)
          })
          //AssignAutoNumber function to get new nr assign to "this.NewInvoice_Number"
          //THEN this.DownloadPO(this.PDFDownloadOnly)  
        }
        else{
          alert('Please check all the Order line values')
        }
        
      },
      
      ProcessNewInvoice(doc){
        let vm = this
        vm.$store.commit('setCustomProcessingDialogText','Uploading PDF')
        //console.log(this.NewPOLineItems,this.SelectedTransactionLineItems,this.ComputedSOInvoiceObject)
        let NewPOObject = this.ComputedSOInvoiceObject
        //so actually we need to get autonumber. But first let's create autonumber field, see it's logic and take it from there see if it can be used
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        //console.log(blobpdf,bloburl)
        let storagelocation = 'Sales_Invoices/'+vm.OfficialSelectedSalesOrder.id+'/'+NewPOObject.Invoice_Number
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(blobpdf);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Preparing Invoice')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: NewPOObject.Invoice_Number,
                            filename: NewPOObject.Invoice_Number
                          }
                          if(vm.OfficialSelectedSalesOrder.Company){
                            NewPOObject.Company = vm.OfficialSelectedSalesOrder.Company
                            NewPOObject.Companyid = vm.OfficialSelectedSalesOrder.Companyid
                          }
                          NewPOObject.Owner = vm.OfficialSelectedSalesOrder.Owner
                          NewPOObject.Ownerid = vm.OfficialSelectedSalesOrder.Ownerid
                          NewPOObject.COGS_Total = vm.COGSLatestQuotedPriceTotal
                          NewPOObject.Sub_Total = vm.POSubTotal
                          NewPOObject.Invoice_Total = vm.VatExclusiveInvoiceTotal
                          NewPOObject.Grand_Total = vm.GrandTotal
                          NewPOObject.Tax_Total = vm.TaxTotal
                          NewPOObject.Tax_Percentage = vm.TaxPercentage
                          NewPOObject.Latest_PDF_File = PDF_File
                          NewPOObject.LookupObj = vm.LookupObjSelectedSalesOrder
                          NewPOObject.ReferenceProp = 'Invoice_Number'
                          NewPOObject.Reference_Number = vm.SelectedSalesOrder.id
                          NewPOObject.Invoice_Date = new Date()
                          if(vm.StoreOrderEntity){
                          NewPOObject.Invoice_Type = {ID: 1000001, Name: 'Store Order'}
                          }
                          delete NewPOObject.RecordPrimaryFieldName
                          console.log(NewPOObject,vm.ComputedNewPOLineItems,vm.SelectedTransactionLineItems,vm.ComputedSOLineItems)
                            vm.$store.commit('setCustomProcessingDialogText','Preparing PO Lines')
                            let finalpolines = vm.ComputedNewPOLineItems.map((polineitem,polineindex) => {
                                delete polineitem.TransactionalLineItems
                                polineitem.Progress = NewPOObject.Progress
                                polineitem.Modified_By = NewPOObject.Modified_By
                                polineitem.Modified_Byid = NewPOObject.Modified_Byid
                                polineitem.Created_By = NewPOObject.Modified_By
                                polineitem.Created_Byid = NewPOObject.Created_Byid
                                //polineitem.Transaction_Docid = NewPOObject.Invoice_Number
                                // polineitem.Delivered = 0
                                // polineitem.Received = 0
                                // polineitem.Returned = 0
                                // polineitem.Invoiced = 0
                              return {Data: polineitem}
                            })
                            vm.$store.commit('setCustomProcessingDialogText','Sending to Server')
                             if(vm.StoreOrderEntity){
                            let bulkoutboundpayload = {
                                    Collection: 'DispatchInstructions',
                                    Data: [],
                                    ParentObj: NewPOObject,
                                    Warehouse: NewPOObject.Warehouseid
                                  }
                                  bulkoutboundpayload.Data = finalpolines
                                  console.log('bulkoutboundpayload',bulkoutboundpayload)                               
                                  const functions = firebase.functions();
                                  const ConsumeBulkStockQty = functions.httpsCallable('ConsumeBulkStockQty');
                                  ConsumeBulkStockQty(bulkoutboundpayload).then(result => {
                                    vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+NewPOObject.Invoice_Number)
                                      setTimeout(() => {
                                        vm.$store.commit('setCustomProcessingDialog',false)
                                        setTimeout(() => {
                                          // /WarehouseTransaction/Z8WSyTNan523HzF3GOWk/INV-00000002?TransactionType=DispatchInstructions
                                            vm.$router.push('/WarehouseTransaction/'+vm.SelectedWarehouse.id+'/'+NewPOObject.Invoice_Number+'?TransactionType=DispatchInstructions')
                                          }, 500)
                                      }, 500);
                                  })
                             }
                             else if(vm.CashLoanEntity){
                              vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+NewPOObject.Invoice_Number)
                              let pack = vm.SelectedPackage
                              let packageitems = pack.Line_Items
                              let index = packageitems.indexOf(vm.InstallmentLine)
                              let lineobj = vm.InstallmentLine
                              lineobj.Invoice_Number = vm.NewInvoice_Number
                              packageitems.splice(index,1,lineobj)
                              console.log(packageitems)
                              let DateFields = ['Due_Date','Invoice_Date','New_Due_Date']
                              DateFields.map(field => {
                                if(NewPOObject[field]){
                                  NewPOObject[field] = {Type: 'Date', Date: NewPOObject[field]}
                                }
                              })
                               let invpayload = {
                                    Collection: 'Sales_Invoices',
                                    ParentObj: NewPOObject,
                                    IDProp: 'Invoice_Number',
                                    OrderPropUpdates: [
                                      {Prop: 'Status',Value: vm.Record.Status},{Prop: 'Status_Reason',Value: vm.Record.Status_Reason},
                                      {Prop: 'Package',Value: pack},
                                      {Prop: 'Latest_Invoice_PDF',Value: PDF_File}
                                    ],
                                    siteid: this.$store.state.ActiveSuiteid
                                    //we do not update "lookupobj" now it does not matter
                                  }
                                  
                                   if(this.InstallmentLineNumber === 1){
                                    //yes we need COGS but DO NOT GET CONFUSED
                                    // WHETHER WE NEED TO CAPTURE COGS, BUT WHETHER WE CAPTURE ACC REC PER INSTALLMENT INV, OR FOR THE FULL ORIGINAL REPAYMENT AMOUNT BOB - THIS IS WHERE IT WOULD HAPPEN
                                    invpayload.OrderPropUpdates.push({Prop: 'First_Invoice',Value: vm.NewInvoice_Number},{Prop: 'First_Invoice_PDF',Value: PDF_File})
                                    //so now we capture the first invoice, after which never again is it updated. also we capture latestinv every time, while first inv pdf ony once
                                    //So now we just gotta ask whether acc rec is done first time, or per inv. because if first time all code need updating
                                   }
                                  const functions = firebase.functions();
                                  const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
                                  console.log('invpayload',invpayload)
                                  CreateFinancialDocument(invpayload).then(result => {
                                    vm.$store.commit('setPDFExportDoc','')
                                    vm.$store.commit('setPDFDataRecord','')
                                    vm.$store.commit('setOrderConfirmationPDF','')
                                    //okay potentially missing out on clientrecords crate here right?
                                    let record = vm.Record
                                          let NewComms = {
                                            description: NewPOObject.Invoice_Number,
                                            Created_On: new Date(),   
                                            Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
                                            Created_Byid: vm.userLoggedIn.id,
                                            senderid: vm.userLoggedIn.id,
                                            relatedid: record.id,
                                            relatedtype: vm.CurrentEntity.DisplayName,
                                            recordlocation: '/'+vm.CurrentEntity.SingleName.split(' ').join('')+'/'+record.id,
                                            topicid: vm.CurrentEntity.id,
                                          }
                                          NewComms.StorageRef = storagelocation
                                          NewComms.newdocid = NewPOObject.Invoice_Number
                                          NewComms.FileType = PDF_File.FileType
                                          NewComms.lastModifiedDate = PDF_File.ModifiedDate
                                          NewComms.fileurl = PDF_File.fileurl
                                          NewComms.Uploadfilename = NewPOObject.Invoice_Number
                                          vm.SaveNewClientcomms(NewComms,record)
                                          
                                    vm.$store.commit('setCustomProcessingDialog',false)
                                    vm.$emit('DeactivateStateDialog')
                                    vm.NEWSOInvoiceDialog = false
                                  })
                             
                             }
                          //  })
                          
                          //now I got the Po object, but I still need 1. PO Line items, and 2.SO Line items...
                        })
      },
      SaveNewClientcomms(NewComms,record){
        //  commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
             // db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id)
         let clientrecordobj = {
                name: record[this.CurrentEntity.RecordPrimaryField.Name],
                topicid: this.CurrentEntity.id,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Clientid: record.Userid
            }  
          if(!this.HasClientRecord){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id).set(clientrecordobj) 
          }
         
        let commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
        //console.log(commscol)
        let owneruser = record.User
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.CurrentEntity.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.CurrentEntity.RecordPrimaryField.Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
      CheckClientRecord(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(this.Record.id).onSnapshot(snapshot => {
                    let clientrecordata = snapshot.data()
                    if(clientrecordata){
                      this.HasClientRecord = true
                    }
            })
      },
      GetPOPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Sales_Invoices').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.PDFExportFunctionData = functiondata
        })
      },
    }
}
</script>

<style>

</style>



